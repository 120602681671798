<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12 sm4">
        <va-card :title="$t('trainings.types.view')">
          <actions
            slot="actions"
            crud-links="trainingTypes"
            controller="TrainingTypes"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.name')">
            {{ trainingType.name }}
          </text-list>
        </va-card>
      </div>
      <div class="flex xs2 sm8">
        <va-card :title="$t('trainings.types.inputs.certificateImageInput')">
          <div
            class="img-container"
            :style="{'background-image': `url('${certificateImage}')`}"
          ></div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'training-types-view',
  components: {
    Actions,
    TextList,
  },
  data () {
    return {
      error: false,
      trainingType: {},
      loading: false,
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    certificateImage () {
      return this.getBaseSrc() + (this.trainingType.image_path || '/img/loading.gif')
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    getBaseSrc () {
      let src = ''
      if (process.env.NODE_ENV === 'development') {
        const base = this.$http.defaults.baseURL.match(/^https?:\/\/[^/]+/g)[0]
        src = base
      }
      return src
    },
    routeBuilder (id) {
      return `training-types/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const typeId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(typeId))
      } catch (err) {
        // console.log('Error fetching training type data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.trainingType = u.data.data
    },
  },
}
</script>

<style lang="scss" scoped>
.img-container {
  width: 100%;
  height: auto;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
</style>
